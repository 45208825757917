import { FunctionComponent, useEffect, useRef } from "react";
import { Language, MainMenuItem, PageState } from "../types/index.js";
import { useKeyDown } from "../utils/hooks.js";
import Icon from "./Icon.js";
import MainMenuLevelLeftNav from "./MainMenuLevelLeftNav.js";

interface Props {
  isPreview: boolean;
  languageId: Language;
  pages: PageState;
  currentMenuItem: MainMenuItem;
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
  onClose: () => void;
}

const LeftOverlaySubmenu: FunctionComponent<Props> = ({
  isPreview,
  languageId,
  currentMenuItem,
  pages,
  activePagePath,
  fallbackLanguageId,
  onClose,
}) => {
  const submenuRef = useRef<HTMLDivElement>(null);
  useKeyDown("Escape", onClose);

  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      const target = event.target as Element | null;
      const clickedOutside = !submenuRef.current?.contains(target);
      clickedOutside && onClose();
    };
    document.addEventListener("click", onClick, { passive: true });

    return () => {
      document.removeEventListener("click", onClick);
    };
  }, []);

  return (
    <div ref={submenuRef} className="LeftOverlayNav__Submenu">
      <button
        className="Btn--bare LeftOverlayNav__BackBtn"
        type="button"
        onClick={onClose}
      >
        <Icon glyph="arrow-left" />
      </button>

      <ul className="MainMenu__List MainMenu__ListLevel0">
        <MainMenuLevelLeftNav
          currentMenuItem={currentMenuItem}
          isPreview={isPreview}
          languageId={languageId}
          parentId={currentMenuItem.id}
          pages={pages}
          menuLevel={0}
          activePagePath={activePagePath}
          fallbackLanguageId={fallbackLanguageId}
          onMenuItemClick={onClose}
        />
      </ul>
    </div>
  );
};

export default LeftOverlaySubmenu;
