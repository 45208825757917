import ClassNames from "classnames";
import { EmblaCarouselType, EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import {
  Children,
  DependencyList,
  EffectCallback,
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { SliderEffect } from "../types/index.js";

interface Props {
  slidesToShow: number;
  className?: string;
  containerClassName?: string;
  slideClassName?: string;
  onSliderLoaded: (slider: EmblaCarouselType) => void;
  sliderEffect: SliderEffect;
  isPreview: boolean;
  slideIds: string[];
  activePreviewSlideId: string | undefined;
  children: ReactNode;
}

const EmblaCarouselSlide: FunctionComponent<{
  children: ReactNode;
  className: string | undefined;
  slidesToShow: number;
  isSelected: boolean;
}> = ({ children, className, slidesToShow, isSelected }) => (
  <div
    className={ClassNames("EmblaCarousel__Slide", className, {
      "EmblaCarousel__Slide--1/2": slidesToShow === 2,
      "EmblaCarousel__Slide--1/3": slidesToShow === 3,
      "EmblaCarousel__Slide--1/4": slidesToShow === 4,
      "EmblaCarousel__Slide--1/5": slidesToShow === 5,
      "EmblaCarousel__Slide--1/6": slidesToShow === 6,
      "EmblaCarousel__Slide--is-selected": isSelected,
    })}
  >
    {children}
  </div>
);

const isServer = typeof navigator === "undefined";

const Slider: FunctionComponent<Props> = ({
  children,
  className,
  containerClassName,
  slideClassName,
  onSliderLoaded,
  slidesToShow: slidesToShowProp,
  sliderEffect,
  isPreview,
  activePreviewSlideId,
  slideIds,
}) => {
  const childrenArray = Children.toArray(children);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  // loop needs to be false with the fade effect,
  // otherwise it causes a glitch when navigating with the arrows
  const isSlide = sliderEffect === "slide";
  const options: EmblaOptionsType = {
    loop: isSlide,
    skipSnaps: isSlide,
    align: "start",
    draggable: !isPreview,
  };
  const slidesToShow = sliderEffect === "slide" ? slidesToShowProp : 1;

  const [emblaRef, emblaInstance] = useEmblaCarousel(options);

  useDidMountEffect(() => {
    emblaInstance?.reInit(options);
  }, [slidesToShow, childrenArray.length]);

  useDidMountEffect(() => {
    emblaInstance?.reInit({
      ...options,
      loop: sliderEffect === "slide",
      skipSnaps: isSlide,
    });
  }, [sliderEffect]);

  useEffect(() => {
    // Disable transform if effect is set to fade.
    emblaInstance
      ?.internalEngine()
      .translate.toggleActive(sliderEffect === "slide");
    emblaInstance?.internalEngine().translate.clear();
  }, [sliderEffect, emblaInstance]);

  useEffect(() => {
    if (!emblaInstance) return;
    onSliderLoaded(emblaInstance);
    emblaInstance.on("select", () =>
      setCurrentSlideIndex(emblaInstance?.selectedScrollSnap() ?? 0)
    );
  }, [emblaInstance]);

  // Slide to active module in preview
  useEffect(() => {
    if (!activePreviewSlideId || !emblaInstance) return;
    const index = slideIds.indexOf(activePreviewSlideId);
    index >= 0 && emblaInstance.scrollTo(index);
  }, [activePreviewSlideId, emblaInstance]);

  const carouselClassNames = ClassNames(
    "EmblaCarousel",
    { "EmblaCarousel--is-ready": emblaInstance },
    className
  );
  const containerClassNames = ClassNames(
    "EmblaCarousel__Container",
    { "EmblaCarousel__Container--fade": sliderEffect === "fade" },
    containerClassName
  );

  return isServer ? (
    <div className={carouselClassNames}>
      <div className={containerClassNames}>
        {childrenArray
          .map((child, index) => (
            <EmblaCarouselSlide
              key={index}
              className={slideClassName}
              slidesToShow={slidesToShow}
              isSelected={false}
            >
              {child}
            </EmblaCarouselSlide>
          ))
          .slice(0, slidesToShow)}
      </div>
    </div>
  ) : (
    <div ref={emblaRef} className={carouselClassNames}>
      <div className={containerClassNames}>
        {childrenArray.map((child, index) => (
          <EmblaCarouselSlide
            key={index}
            className={slideClassName}
            slidesToShow={slidesToShow}
            isSelected={currentSlideIndex === index}
          >
            {child}
          </EmblaCarouselSlide>
        ))}
      </div>
    </div>
  );
};

/**
 * Same as `useEffect`, but doesn’t fire on the first render,
 * only on the ≥ 2nd renders.
 * This allows make the effect fire only if the dependencies change.
 */
const useDidMountEffect = (effect: EffectCallback, deps: DependencyList) => {
  const didMount = useRef(false);

  useEffect(() => {
    didMount.current ? effect() : (didMount.current = true);
  }, deps);
};

export default Slider;
