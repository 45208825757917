import ClassNames from "classnames";
import { FunctionComponent, RefObject, useEffect } from "react";
import { style } from "typestyle";
import i18n from "../i18n/index.js";
import {
  ColorScheme,
  Language,
  MainMenuItem,
  PageState,
} from "../types/index.js";
import { useKeyDown } from "../utils/hooks.js";
import { getTranslations } from "../utils/utils.js";
import MainMenuListCollapsible, {
  defaultClassNames,
} from "./MainMenuListCollapsible.js";

interface Props {
  scheme: ColorScheme;
  isPreview: boolean;
  pages: PageState;
  languageId: Language;
  topLevelMainMenuItems: MainMenuItem[];
  activePagePath: string[];
  fallbackLanguageId: Language | undefined;
  isOpen: boolean;
  onClose: () => void;
  outerRef: RefObject<HTMLDivElement>;
  className?: string;
  showMenuCaption: boolean;
}

const HamburgerMenuList: FunctionComponent<Props> = ({
  scheme,
  isPreview,
  pages,
  languageId,
  topLevelMainMenuItems,
  activePagePath,
  fallbackLanguageId,
  isOpen,
  onClose,
  outerRef,
  className,
  showMenuCaption,
}) => {
  useKeyDown("Escape", onClose);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      // Cast the target to HTMLElement,
      // see https://typescript.codeplex.com/discussions/432211
      const target = event.target as HTMLElement;

      // Close the menu if an area outside it was clicked
      const clickedOutside = !outerRef.current?.contains(target);
      clickedOutside && onClose();
    };

    document.addEventListener("click", handleClick, { passive: true });

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div
      className={ClassNames(
        style({
          backgroundColor: scheme.primary.background,
          color: scheme.primary.text,
        }),
        "HeaderModule__MainMenuHamburger__Box",
        {
          "HeaderModule__MainMenuHamburger__Box--open": isOpen,
        },
        className
      )}
    >
      {showMenuCaption && (
        <div className="HeaderModule__MainMenuHamburgerCaption">
          {getTranslations(languageId, i18n).menu}
        </div>
      )}
      <MainMenuListCollapsible
        isPreview={isPreview}
        languageId={languageId}
        onMenuItemClick={onClose}
        menuItems={topLevelMainMenuItems}
        pages={pages}
        activePagePath={activePagePath}
        fallbackLanguageId={fallbackLanguageId}
        menuLevel={0}
        collapseLevel={0}
        classNames={defaultClassNames}
      />
    </div>
  );
};

export default HamburgerMenuList;
