import ClassNames from "classnames";
import { EmblaCarouselType } from "embla-carousel";
import { FunctionComponent, useState } from "react";
import { style } from "typestyle";
import {
  AspectRatio,
  ColorScheme,
  ContainerQueries,
  HighlightsDisplayType,
  HighlightsItem,
  Language,
  MediaAlign,
  RichEditorState,
  TitleAndSubtitleTextAlign,
} from "../types/index.js";
import { getPaddingForAspectRatioBox } from "../utils/utils.js";
import CroppedImage from "./CroppedImage.js";
import HighlightHeading from "./HighlightHeading.js";
import ModuleHeadings from "./ModuleHeadings.js";
import ReadMoreCollapse from "./ReadMoreCollapse.js";
import Slider from "./Slider.js";
import SliderArrow from "./SliderArrow.js";

interface Props {
  title: string;
  subtitle: string;
  textAlign: TitleAndSubtitleTextAlign;
  isFirstOnPage: boolean;
  scheme: ColorScheme;
  items: HighlightsItem[];
  collapsedLinesCount: number | undefined;
  languageId: Language;
  richEditor: RichEditorState;
  isPreview: boolean;
  queries: ContainerQueries;
  displayType: HighlightsDisplayType;
  mediaAspectRatio: AspectRatio;
  activeModuleId: string | undefined;
  transparentSliderArrowBackground: boolean;
  mediaAlign: MediaAlign;
}

const HighlightsLayout7: FunctionComponent<Props> = ({
  title,
  subtitle,
  textAlign,
  isFirstOnPage,
  scheme,
  items,
  collapsedLinesCount,
  languageId,
  richEditor,
  isPreview,
  queries,
  displayType,
  mediaAspectRatio,
  activeModuleId,
  transparentSliderArrowBackground,
  mediaAlign,
}) => {
  const [slider, setSlider] = useState<EmblaCarouselType>();
  const calculatedAspectRatio = getAspectRatio(queries, mediaAspectRatio);
  const hasHighlightsTitle = !!title;

  const highlights = items.map(
    (
      { id, title, subtitle, picture, imageDetail, buttons, text, textAlign },
      index
    ) => (
      <article
        key={id}
        className={ClassNames(
          "HighlightsLayout7__Item",
          {
            "HighlightsLayout7__Item--list": displayType === "list",
          },
          style({ color: scheme.main.text })
        )}
      >
        <div className="HighlightsLayout7__ItemPicture">
          <CroppedImage
            aspectRatio={getAspectRatio(queries, mediaAspectRatio)}
            picture={picture}
            width={1280}
            detail={imageDetail}
            sizes="(min-width: 640px) 50vw, (min-width: 1024px) 33vw, (min-width: 1600px) 500px, 100vw"
          />
        </div>

        <div
          className={ClassNames(
            "HighlightsLayout7__ItemContent",
            style({
              background: scheme.main.background,
              $nest: {
                "&::before": {
                  paddingTop: getPaddingForAspectRatioBox(
                    calculatedAspectRatio
                  ),
                },
              },
            })
          )}
        >
          <div className="HighlightsLayout7__ItemContentInner">
            <div className="HighlightsLayout7__ItemText">
              <HighlightHeading
                title={{
                  value: title,
                  className: ClassNames(
                    "HighlightsLayout7__ItemTitle",
                    `TextAlign--${textAlign.title}`,
                    style({
                      color: scheme.main.title,
                    })
                  ),
                  children: title,
                }}
                subtitle={{
                  value: subtitle,
                  className: ClassNames(
                    "HighlightsLayout7__ItemSubtitle",
                    `TextAlign--${textAlign.subtitle}`
                  ),
                }}
                highlightIndex={index}
                hasHighlightsTitle={hasHighlightsTitle}
                isFirstOnPage={isFirstOnPage}
              />

              {text && (
                <div
                  className={ClassNames(
                    "HighlightsLayout7__Description",
                    style({
                      borderColor: scheme.main.title,
                    })
                  )}
                >
                  {collapsedLinesCount !== undefined ? (
                    <ReadMoreCollapse
                      backgroundColor={scheme.main.background}
                      languageId={languageId}
                      collapsedLinesCount={collapsedLinesCount}
                      isEditing={!!richEditor[id]}
                    >
                      {text}
                    </ReadMoreCollapse>
                  ) : (
                    text
                  )}
                </div>
              )}
            </div>
            {!!buttons.length && (
              <div className="HighlightsLayout7__Buttons">{buttons}</div>
            )}
          </div>
        </div>
      </article>
    )
  );

  return (
    <div
      className={ClassNames(
        "HighlightsLayout7",
        `HighlightsLayout7--media-${mediaAlign}`,
        style({ background: scheme.main.separator })
      )}
    >
      <div className="Module__Wrapper Module__Wrapper--default-padding">
        <ModuleHeadings
          scheme={scheme}
          isFirstOnPage={isFirstOnPage}
          textAlign={textAlign}
          title={title}
          subtitle={subtitle}
        />

        <div className="HighlightsLayout7__Items">
          {displayType === "slider" && (
            <Slider
              className="HighlightsLayout7__Slider"
              containerClassName="HighlightsLayout7__SliderContainer"
              slideClassName="HighlightsLayout7__Slide"
              isPreview={isPreview}
              sliderEffect="slide"
              onSliderLoaded={setSlider}
              slidesToShow={1}
              activePreviewSlideId={activeModuleId}
              slideIds={items.map(({ id }) => id)}
            >
              {highlights}
            </Slider>
          )}
          {displayType === "list" && (
            <div className="HighlightsLayout7__List">{highlights}</div>
          )}
          {displayType === "slider" && items.length > 1 && (
            <div className="HighlightsLayout7__SliderArrows">
              <SliderArrow
                className="HighlightsLayout7__SliderArrow HighlightsLayout7__SliderArrow--left"
                iconClassName="HighlightsLayout7__SliderArrowIcon"
                direction="left"
                onClick={() => slider?.scrollPrev()}
                scheme={scheme}
                transparentBackground={transparentSliderArrowBackground}
              />
              <SliderArrow
                className="HighlightsLayout7__SliderArrow HighlightsLayout7__SliderArrow--right"
                iconClassName="HighlightsLayout7__SliderArrowIcon"
                direction="right"
                onClick={() => slider?.scrollNext()}
                scheme={scheme}
                transparentBackground={transparentSliderArrowBackground}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const getAspectRatio = (
  queries: ContainerQueries,
  mediaAspectRatio: AspectRatio
): AspectRatio => {
  if (queries["Query--large"]) return mediaAspectRatio;
  if (queries["Query--medium"]) return 1.7778;
  return 1.3333;
};

export default HighlightsLayout7;
