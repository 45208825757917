import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { style } from "typestyle";
import { checkIsPlaceholder } from "../selectors/pictures.js";
import {
  ImageCropParams,
  ImageDetail,
  LazyImageProps,
  Picture,
  PictureQueryParams,
  Point,
  StoreState,
} from "../types/index.js";
import {
  appendQueryToUrl,
  getPaddingForAspectRatioBox,
  limit,
  round,
} from "../utils/utils.js";
import LazyImage from "./LazyImage.js";

interface Props {
  picture: Picture;
  aspectRatio: number | undefined;
  width: number;
  detail: ImageDetail;
  lazyLoad?: boolean;
  sizes: string;
}

interface StateProps {
  originalAspectRatio: number;
}

type ReduxProps = ConnectedProps<typeof connector>;

/**
 * An MSS image which can be cropped and fixed to a certain
 * aspect ratio. In addition, the most important point inside
 * the image can be specified which is then displayed as much
 * in the center of the image as possible.
 *
 * If `aspectRatio === undefined` (which means variable height),
 * the aspect ratio of the resulting image after cropping it is used.
 * Then an attempt is made to center the selected most important point.
 */
const CroppedImage: FunctionComponent<Props & ReduxProps> = ({
  aspectRatio,
  width,
  detail,
  originalAspectRatio,
  picture,
  picture: { title, url, category },
  sizes,
  lazyLoad = true,
}) => {
  const isPlaceholder = checkIsPlaceholder({ category });
  const queryParams = getImageUrlParams({
    width,
    pictureWidth: picture.width,
    targetAspectRatio: aspectRatio,
    detail,
    originalAspectRatio,
  });
  const src = isPlaceholder ? url : appendQueryToUrl(url, queryParams);
  const srcSet = getSrcSet({
    detail,
    originalAspectRatio,
    targetAspectRatio: aspectRatio,
    picture,
    cropWidth: queryParams.cropW ?? 1,
  });

  const objectPosition = getObjectPosition(detail.importantPoint);
  const objectPositionClass = style({
    objectPosition: `${objectPosition.x}% ${objectPosition.y}%`,
  });

  const imageProps: LazyImageProps = {
    className: ClassNames(
      "FullImageContainer__Image",
      "AspectRatioContainer__Content",
      objectPositionClass,
      { Placeholder__Image: isPlaceholder }
    ),
    src,
    width: 1600,
    height: 900,
    alt: title ?? "",
    sizes: isPlaceholder ? undefined : sizes,
    srcSet: isPlaceholder ? undefined : srcSet,
    loading: lazyLoad ? "lazy" : "eager",
  };

  const aspectRatioStyle = style({
    $nest: {
      "&::before":
        aspectRatio !== undefined
          ? { paddingTop: getPaddingForAspectRatioBox(aspectRatio) }
          : { content: "none" },
    },
  });

  return (
    <div
      className={ClassNames(
        "AspectRatioContainer",
        "FullImageContainer",
        aspectRatioStyle,
        { Placeholder: isPlaceholder }
      )}
    >
      <LazyImage {...imageProps} />
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { mediaLibrary },
  { picture: { id } }
): StateProps => {
  const picture = mediaLibrary.pictures[id];
  return {
    // Fallback to 1 if no width / height defined
    originalAspectRatio: (picture?.width || 1) / (picture?.height || 1),
  };
};

const getSrcSet = ({
  detail,
  targetAspectRatio,
  originalAspectRatio,
  picture,
  cropWidth,
}: {
  detail: ImageDetail;
  targetAspectRatio: number | undefined;
  originalAspectRatio: number;
  picture: Picture;
  cropWidth: number;
}): string => {
  const maxWidth = Math.round(picture.width * cropWidth);
  // Omit widths greater than the picture width
  // and take `cropWidth` into account.
  const filteredWidths = [320, 480, 800, 1280, 1600, 1920].filter(
    (width) => width <= maxWidth
  );

  // Add the maximum image width if necessary.
  const largestWidth = filteredWidths.slice(-1)[0];
  const widths = filteredWidths.concat(
    largestWidth && maxWidth > largestWidth + 100 ? [maxWidth] : []
  );

  return widths
    .map((width) => {
      const queryParams = getImageUrlParams({
        width,
        pictureWidth: picture.width,
        targetAspectRatio,
        detail,
        originalAspectRatio,
      });
      return `${appendQueryToUrl(picture.url, queryParams)} ${width}w`;
    })
    .join(",\n");
};

export const getImageBoundingRect = ({
  detail: { crop, importantPoint },
  originalAspectRatio,
  targetAspectRatio,
}: {
  detail: ImageDetail;
  targetAspectRatio: number | undefined;
  originalAspectRatio: number;
}): ImageCropParams => {
  const croppedAreaAspectRatio =
    originalAspectRatio * (crop.width / crop.height);
  const resultingAspectRatio = targetAspectRatio ?? croppedAreaAspectRatio;

  const fitsAsLandscape = resultingAspectRatio > croppedAreaAspectRatio;

  const combinedAspectRatio = fitsAsLandscape
    ? croppedAreaAspectRatio / resultingAspectRatio
    : resultingAspectRatio / croppedAreaAspectRatio;

  const width = fitsAsLandscape ? crop.width : crop.width * combinedAspectRatio;
  const height = fitsAsLandscape
    ? crop.height * combinedAspectRatio
    : crop.height;

  return {
    x: fitsAsLandscape
      ? crop.x
      : limit({
          value: crop.x + importantPoint.x * crop.width - width / 2,
          min: crop.x,
          max: crop.x + crop.width - width,
        }),
    y: fitsAsLandscape
      ? limit({
          value: crop.y + importantPoint.y * crop.height - height / 2,
          min: crop.y,
          max: crop.y + crop.height - height,
        })
      : crop.y,
    width,
    height,
  };
};

const getImageUrlParams = ({
  width,
  pictureWidth,
  targetAspectRatio,
  originalAspectRatio,
  detail,
}: {
  width: number;
  pictureWidth: number;
  targetAspectRatio: number | undefined;
  originalAspectRatio: number;
  detail: ImageDetail;
}): PictureQueryParams => {
  const params = getImageBoundingRect({
    detail,
    targetAspectRatio,
    originalAspectRatio,
  });
  const isCropped =
    params.x > 0 || params.y > 0 || params.height < 1 || params.width < 1;

  const targetWidth = Math.round(Math.min(width, pictureWidth * params.width));

  return isCropped
    ? {
        w: targetWidth,
        cropX: round(params.x, 4),
        cropY: round(params.y, 4),
        cropW: round(params.width, 4),
        cropH: round(params.height, 4),
      }
    : { w: targetWidth };
};

/**
 * Calculates the values for the `object-position` property.
 * @returns x, y (percentage coordinates)
 */
const getObjectPosition = ({ x, y }: Point): Point => ({
  x: round(
    limit({
      value: x + (x - 0.5) / 2,
      min: 0,
      max: 1,
    }) * 100,
    6
  ),
  y: round(
    limit({
      value: y + (y - 0.5) / 2,
      min: 0,
      max: 1,
    }) * 100,
    6
  ),
});

const connector = connect(mapStateToProps);

export default connector(CroppedImage);
