import ClassNames from "classnames";
import { FunctionComponent } from "react";

interface Props {
  isActive: boolean;
  isPreview: boolean;
  url: string | undefined;
  placeholder: string;
  className: string;
}

const EmbeddedGoogleMaps: FunctionComponent<Props> = ({
  isActive,
  isPreview,
  url,
  placeholder,
  className,
}) => (
  <div
    className={ClassNames(
      "AspectRatioContainer",
      "EmbeddedGoogleMaps",
      className
    )}
  >
    {url && (
      <iframe
        scrolling="no"
        allowFullScreen={true}
        className={ClassNames(
          "EmbeddedGoogleMaps__Iframe",
          "AspectRatioContainer__Content",
          {
            "EmbeddedGoogleMaps__Iframe--no-click": !isActive && isPreview,
          }
        )}
        loading="lazy"
        src={url}
      />
    )}
    {!url && (
      <div className="EmbeddedGoogleMaps__Empty AspectRatioContainer__Content">
        {placeholder}
      </div>
    )}
  </div>
);

export default EmbeddedGoogleMaps;
