import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { style } from "typestyle";
import {
  BaseModuleProps,
  ColorScheme,
  StoreState,
  WeatherWebcamLayoutVariant,
  WebcamModuleSettings,
  WebcamSize,
} from "../../types/index.js";
import { getWebcamParentLayout } from "../../utils/utils.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";
import MultilineText from "../MultilineText.js";
import RichEditorWrapper from "../RichEditorWrapper.js";

interface Props extends BaseModuleProps<WebcamModuleSettings> {
  scheme: ColorScheme;
}

interface StateProps {
  parentLayout: WeatherWebcamLayoutVariant | undefined;
}

type ReduxProps = ConnectedProps<typeof connector>;

const WebcamModule: FunctionComponent<Props & ReduxProps> = ({
  scheme,
  pageId,
  isFirstOnPage,
  isPreview,
  translatedModule,
  translatedModule: {
    id,
    settings: { url, textAlign, layoutVariant },
    translation: {
      languageId,
      settings: { title, subtitle, imageCaption },
    },
  },
  parentLayout,
  queries,
}) => {
  const size: WebcamSize =
    parentLayout === "horizontal" || !queries["Query--medium"]
      ? "big"
      : translatedModule.settings.size;
  const subtitleIsSplit = queries["Query--medium"] && size === "small";

  return (
    <ModuleWithHeadings
      title={title}
      subtitle={subtitle}
      id={id}
      colors={{ background: scheme.main.background, color: scheme.main.text }}
      className="WebcamModule"
    >
      <ModuleHeadings
        scheme={scheme}
        isFirstOnPage={isFirstOnPage}
        textAlign={textAlign}
        title={title}
        subtitle={subtitleIsSplit ? undefined : subtitle}
      />

      <div
        className={ClassNames("WebcamModule__Inner", {
          "WebcamModule__Inner--image-big": size === "big",
          "WebcamModule__Inner--image-small": size === "small",
        })}
      >
        <figure
          className={ClassNames("WebcamModule__Image", {
            "WebcamModule__Image--image-last": layoutVariant === "image-last",
          })}
        >
          {url ? (
            <img
              className="FullImage"
              loading="lazy"
              alt={imageCaption ?? ""}
              src={url}
            />
          ) : (
            <div className="AspectRatioContainer Placeholder">
              <img
                className="FullImage WebcamModule__PlaceholderImg AspectRatioContainer__Content"
                loading="lazy"
                alt={imageCaption ?? ""}
                src="/image.svg"
              />
            </div>
          )}

          {imageCaption && (
            <figcaption
              className={ClassNames(
                "WebcamModule__ImageCaption",
                style({
                  backgroundColor: scheme.primary.background,
                  color: scheme.primary.text,
                })
              )}
            >
              {imageCaption}
            </figcaption>
          )}
        </figure>

        <div className="WebcamModule__Text">
          {subtitle && subtitleIsSplit && (
            <div
              className={ClassNames(
                "Module__Subtitle",
                "WebcamModule__Subtitle",
                `TextAlign--${textAlign.subtitle}`,
                style({ color: scheme.main.text })
              )}
            >
              <MultilineText text={subtitle} />
            </div>
          )}

          <RichEditorWrapper
            module={translatedModule}
            pageId={pageId}
            languageId={languageId}
            isPreview={isPreview}
            scheme={scheme}
            className={`WebcamModule__Description TextAlign--${textAlign.description}`}
          />
        </div>
      </div>
    </ModuleWithHeadings>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { modules },
  { translatedModule }
): StateProps => ({
  parentLayout: getWebcamParentLayout(modules, translatedModule),
});

const connector = connect(mapStateToProps);

export default connector(WebcamModule);
