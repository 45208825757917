import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { style } from "typestyle";
import {
  AspectRatio,
  EmbeddedGoogleMapsModuleSettings,
  TranslatedModule,
} from "../../types/index.js";
import { getPaddingForAspectRatioBox } from "../../utils/utils.js";
import EmbeddedGoogleMaps from "../EmbeddedGoogleMaps.js";

interface Props {
  isActive: boolean;
  isPreview: boolean;
  aspectRatio: AspectRatio;
  translatedModule: TranslatedModule<EmbeddedGoogleMapsModuleSettings>;
}

const EmbeddedGoogleMapsModule: FunctionComponent<Props> = ({
  isActive,
  isPreview,
  aspectRatio,
  translatedModule: {
    translation: {
      settings: { url },
    },
  },
}) => (
  <EmbeddedGoogleMaps
    isActive={isActive}
    isPreview={isPreview}
    placeholder="Google Maps"
    url={url}
    className={ClassNames(
      style({
        $nest: {
          "&::before": {
            paddingTop: getPaddingForAspectRatioBox(aspectRatio),
          },
        },
      })
    )}
  />
);

export default EmbeddedGoogleMapsModule;
