import ClassNames from "classnames";
import { FunctionComponent, MouseEvent } from "react";
import { style } from "typestyle";
import { AspectRatio, ColorScheme } from "../types/index.js";
import { getPaddingForAspectRatioBox } from "../utils/utils.js";
import SliderArrow from "./SliderArrow.js";

interface Props {
  scheme: ColorScheme;
  slidesPerView: number;
  mediaAspectRatio: AspectRatio;
  type: "prev" | "next";
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  hasImages: boolean;
}

const MultiBoxSliderArrow: FunctionComponent<Props> = ({
  slidesPerView,
  scheme,
  type,
  onClick,
  mediaAspectRatio,
  hasImages,
}) => (
  <div
    className={ClassNames(
      "MultiBox__NavigationWrapper",
      `MultiBox__NavigationWrapper--${slidesPerView}`,
      `MultiBox__NavigationWrapper--${type}`,
      style({
        $nest: {
          "&::before": {
            paddingTop: getPaddingForAspectRatioBox(mediaAspectRatio),
          },
        },
      }),
      {
        "MultiBox__NavigationWrapper--has-images": hasImages,
      }
    )}
  >
    <SliderArrow
      className={`MultiBox__Navigation MultiBox__Navigation--${type}`}
      iconClassName="MultiBox__NavigationIcon"
      scheme={scheme}
      onClick={onClick}
      direction={type === "prev" ? "left" : "right"}
      transparentBackground={false}
    />
  </div>
);

export default MultiBoxSliderArrow;
